'use client'

import { useEffect, useRef, useState } from 'react'
import { Box, Zoom } from '@mui/material'

export function ZoomOnScroll({ children, offset = 0, timeout = 500 }) {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const nodeRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    const node = nodeRef.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isIntersecting) {
          setIsIntersecting(entry.isIntersecting)
        }
      },
      {
        root: null,
        rootMargin: `${offset.toString()}px`,
        threshold: 0.1,
      }
    )

    if (node) {
      observer.observe(node)
    }

    return () => {
      if (node) {
        observer.unobserve(node)
      }
    }
  }, [offset, isIntersecting])

  return (
    <Box ref={nodeRef}>
      <Zoom in={isIntersecting} timeout={timeout}>
        <div>{children}</div>
      </Zoom>
    </Box>
  )
}
