'use client'

import type { CldImageProps } from 'next-cloudinary'
import { CldImage } from 'next-cloudinary'

const env = process.env.VERCEL_ENV ?? 'preview'

export function Image(props: Omit<CldImageProps, 'config'>) {
  return (
    <CldImage
      config={{
        cloud: {
          cloudName: 'pblworks-assets',
        },
      }}
      {...props}
      loading="lazy"
      placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkjMqqBwACbwFG/07t4wAAAABJRU5ErkJggg=="
      src={`${env}/${props.src}`}
    />
  )
}
