'use client'

import { Typography, Container, Box, useTheme } from '@mui/material'
import { Image } from './Image'

const data = {
  title: 'From the leading experts on project-based learning',
  description:
    'After 30 years of deep PBL experience, PBLWorks knows what it takes to help every student succeed.',
  tag: 'PBL Experts',
}

export function Experts() {
  const theme = useTheme()
  return (
    <Box
      sx={{
        pb: 13,
      }}
    >
      <Container>
        <Box
          sx={{
            mb: { sm: 6, lg: 12 },
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ maxWidth: { sm: 302, lg: 479 }, pt: { sm: 10, lg: 28 } }}>
            <Box
              sx={{
                display: 'inline-block',
                color: '#000',
                py: 1,
                px: 1.5,
                border: '1.5px solid #000',
                borderRadius: 2,
                fontSize: 16,
                fontWeight: 700,
                mb: 2.5,
                bgcolor: 'secondary.light',
              }}
            >
              {data.tag}
            </Box>
            <Typography
              sx={{
                fontSize: { xs: 24, lg: 36 },
                lineHeight: { xs: '32px', lg: '40px' },
              }}
              variant="h2"
            >
              {data.title}
            </Typography>
            <Typography
              sx={{ my: 2.5, fontSize: { xs: 18, lg: 20 } }}
              variant="body1"
            >
              {data.description}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: '100%' },
              [theme.breakpoints.up('xl')]: {
                width: 715,
              },
              [theme.breakpoints.up('sm')]: {
                position: 'absolute',
                width: '50vw',
                right: -95,
              },
            }}
          >
            <Image
              alt="Experts in PBL"
              height="926"
              sizes="(max-width: 600px) 100vw,(max-width: 1536px) 50vw, 715px"
              src="experts"
              style={{
                height: 'auto',
                maxWidth: '100%',
              }}
              width="1438"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
